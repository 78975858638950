import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LocationObject, MacropointOrder, MacropointOrderLocation } from '@tradecafe/types/core';
import { DeepReadonly, lookupSegments } from '@tradecafe/types/utils';
import { compact } from 'lodash-es';
import { loadLocations, selectLocationEntities } from 'src/app/store/locations';
import { environment } from 'src/environments/environment';
import { DealsService } from 'src/services/data/deals.service';
import { MacropointService } from 'src/services/data/macropoint.service';
import { waitNotEmpty } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'

interface ShipmentStop {
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral,
  pin: google.maps.marker.PinElement,
  time: number,
  type: 'Origin' | 'Destination',
  location: DeepReadonly<LocationObject>,
}

@Component({
  selector: 'tc-macropoint-order-tracking',
  templateUrl: './macropoint-order-tracking-page.component.html',
  styleUrls: ['./macropoint-order-tracking-page.component.scss'],
})
export class MacropointOrderTrackingPageComponent implements OnInit {
  latestLocationCoordinates: google.maps.LatLng | google.maps.LatLngLiteral;

  @ViewChild('map', { static: false }) map: GoogleMap;
  @ViewChild('locationInfoWindow', { static: false }) locationInfoWindow: MapInfoWindow;

  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())

  protected order: MacropointOrder;
  protected uncertaintyRadiusMeters: number;
  protected apiLoaded: boolean;
  protected latestLocationPin: google.maps.marker.PinElement;
  protected origin: ShipmentStop;
  protected destination: ShipmentStop;

  protected mapOptions: google.maps.MapOptions = {
    zoom: 8, // larger number = zoomed in closer
    streetViewControl: false,
    mapId: environment.shipmentTrackingGoogleMapId,
  }
  protected uncertaintyCircleOptions: google.maps.CircleOptions = {
    fillColor: '#007bff',
    fillOpacity: 0.2,
    strokeColor: '#0056b3',
    strokeOpacity: 0.5,
    strokeWeight: 2
  }
  protected uncertaintyCircleSizeInPixels: number;

  constructor(
    private route: ActivatedRoute,
    private MacropointApi: MacropointService,
    private DealsApi: DealsService,
    private store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadGoogleMapsScript();
    const iconClass = this.order?.last_submitted_request?.mode === 'Ocean' ? 'fa fa-lg fa-ship' : 'fa fa-lg fa-truck';
    this.latestLocationPin = await this.getMarkerPinElement('#ffd514', '#ff8300', '#ff8300', iconClass) // yellow-orange

    this.route.params.subscribe(async params => {
      const orderId = params['order_id'];
      this.order = await this.MacropointApi.get(orderId);
      if(this.order?.latest_location?.latitude && this.order?.latest_location?.longitude) {
        this.latestLocationCoordinates = { lat: this.order.latest_location.latitude, lng: this.order.latest_location.longitude };
        this.uncertaintyRadiusMeters =  this.order.latest_location.uncertainty * 1609.344; // conversion from miles to meters
        this.uncertaintyCircleSizeInPixels = this.calculateCircleSizeInPixels(this.latestLocationCoordinates, this.uncertaintyRadiusMeters);
      }

      if(environment.enableDisplayOfMacropointStops) {
        await this.loadStopLocations();
      }
    });
  }

  private async loadStopLocations() {
    this.store.dispatch(loadLocations({}))
    this.locations$.subscribe(async locations => {
      await this.DealsApi.getDealView(this.order.deal_id, ['segments']).subscribe(async deal => {
        const { earliestVessel, earliestTruck } = lookupSegments(deal.segments)
        const segment = earliestVessel || earliestTruck;
        if(!segment) return;

        const originLocation = locations[segment.attributes.origin_id];
        this.origin = originLocation ? {
          type: 'Origin',
          location: originLocation,
          coordinates: await this.getLocationCoordinates(originLocation),
          time: segment.attributes.actual_pickup_date || segment.attributes.etd_date,
          pin: await this.getMarkerPinElement('#4285F4', '#1A3D91', '#FFFFFF', 'fa fa-lg fa-house'), // blue marker with white glyph
        } : null

        const destinationLocation = locations[segment.attributes.destination_id];
        this.destination = destinationLocation ? {
          type: 'Destination',
          location: destinationLocation,
          coordinates: await this.getLocationCoordinates(destinationLocation),
          time: segment.attributes.actual_delivery_date || segment.attributes.eta_date,
          pin: await this.getMarkerPinElement('#4285F4', '#1A3D91', '#FFFFFF', 'fa fa-lg fa-stop') // blue marker with white glyph
        } : null
      })
    })
  }

  // shows the location info
  protected openLatestLocationInfo(marker: MapAdvancedMarker): void {
    if(!this.order?.latest_location) return;

    this.locationInfoWindow.options = { ...this.locationInfoWindow.options, content: this.generateLatestLocationInfoMarkup(this.order.latest_location) };
    this.locationInfoWindow.openAdvancedMarkerElement(marker.advancedMarker);
  }

  protected openStopLocationInfo(marker: MapAdvancedMarker, stop: ShipmentStop): void {
    if(!this.order?.latest_location?.latitude || !this.order?.latest_location?.longitude) return;

    this.locationInfoWindow.options = { ...this.locationInfoWindow.options, content: this.generateStopInfoMarkup(stop) };
    this.locationInfoWindow.openAdvancedMarkerElement(marker.advancedMarker);
  }

  async getMarkerPinElement(background: string, borderColor: string, glyphColor?: string, glyphiconClass?: string ): Promise<google.maps.marker.PinElement> {
    // PinElement is not shipped with the @angular/google-maps@17.3.0
    // until added, we have to use google.maps.marker.PinElement, which needs to be manually imported
    await google.maps.importLibrary("marker");
    let icon = undefined;
    if(glyphiconClass) {
      icon = document.createElement('div');
      icon.innerHTML = `<i class="${glyphiconClass}"></i>`;
    }
    return new google.maps.marker.PinElement({
      glyph: icon,
      glyphColor: glyphColor || borderColor,
      background,
      borderColor,
    });
  }

  protected onZoomChange(): void {
    this.uncertaintyCircleSizeInPixels = this.calculateCircleSizeInPixels(this.latestLocationCoordinates, this.uncertaintyRadiusMeters);
  }

  // generates HTML markup for the location info window
  private generateLatestLocationInfoMarkup(location: MacropointOrderLocation): string {
    if(!location) return "";

    // inline styles because this will be rendered inside of GoogleMap component, general styles will not apply
    return `
    <div style="font-family: Arial, sans-serif; font-size: 14px; color: #333;">
      <div style="font-weight: bold; margin-bottom: 10px;">Location Details</div>
      <div style="margin-bottom: 5px;"><span style="font-weight: bold;">Address:</span> ${this.formatAddress(location) || 'N/A'}</div>
      <div style="margin-bottom: 5px;"><span style="font-weight: bold;">Coordinates:</span> ${this.formatCoordinates(location.latitude, location.longitude, location.uncertainty)}</div>
      <div style="margin-bottom: 5px;"><span style="font-weight: bold;">Time (Local):</span> ${dayjs.unix(location.approx_location_date_time_in_local_time).utc().format('L LT')}</div>
      <div style="margin-bottom: 5px;"><span style="font-weight: bold;">Time (Your timezone):</span> ${dayjs.unix(location.location_date_time_utc).format('L LT')}</div>
      <div style="margin-bottom: 5px;"><span style="font-weight: bold;">Source:</span> ${location.data_source}</div>
    </div>
    `;
  }

  // generates HTML markup for the location info window
  private generateStopInfoMarkup(stop: ShipmentStop): string {
    if(!stop) return "";

    // inline styles because this will be rendered inside of GoogleMap component, general styles will not apply
    return `
    <div style="font-family: Arial, sans-serif; font-size: 14px; color: #333;">
      <div style="font-weight: bold; margin-bottom: 10px;">${stop.type}</div>
      <div>${compact([stop?.location?.city, stop?.location?.state, stop?.location?.country])}</div>
      <div>Planned time (local): ${dayjs.unix(stop?.time).utc().format('L LT')}</div>
    </div>
    `;
  }

  // presents latitude and longitude number in the standard format, e.g. "12.123456° N, 23.456789° W"
  private formatCoordinates(latitude: number, longitude: number, uncertainty: number) {
    let latDirection = latitude < 0 ? "S" : "N";
    let lonDirection = longitude < 0 ? "W" : "E";
    let coordinates = Math.abs(latitude).toFixed(6) + "° " + latDirection + ", " + Math.abs(longitude).toFixed(6) + "° " + lonDirection;
    if(uncertainty) {
      coordinates += ` ± ${uncertainty}mi`;
    }  

    return coordinates;
  }

  // formats address as comma-separated address segments
  private formatAddress(location: MacropointOrderLocation): string {
    const addressSegments = [location.street1, location.street2, location.neighborhood, location.city, location.postal, location.state, location.country];
    const address = compact(addressSegments).join(', ');
    return address;
  }

  // based on the current zoom level and radius of a circle in meters, calculates the width of the circle in the viewport pixels
  private calculateCircleSizeInPixels(circleCenter: google.maps.LatLng | google.maps.LatLngLiteral, radiusInMeters: number) {
    const earthRadiusInMeters = 6378137;
    const pixelsPerMeterAtEquator = 256 / (2 * Math.PI * earthRadiusInMeters);
    const zoomLevel = this.map?.getZoom() ?? this.mapOptions.zoom;
    const latitude = circleCenter instanceof google.maps.LatLng ? circleCenter.lat() : circleCenter.lat;
    const mapScale = Math.cos(latitude * Math.PI / 180) * Math.pow(2, zoomLevel);
    const circleSizeInPixels = radiusInMeters * pixelsPerMeterAtEquator * mapScale;

    return circleSizeInPixels;
  }

  private getLocationCoordinates(location: DeepReadonly<LocationObject>): Promise<google.maps.LatLng> {
    return new Promise((resolve, reject) => {
      const stringifiedLocation = compact([location.city, location.state, location.country]).join(', ')
      
      const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': stringifiedLocation}, (results, status) => {
            if (status === 'OK') {
              const coordinates = results?.[0]?.geometry?.location;
              resolve(coordinates);
            } else {
              console.warn(`Unable to discover retrieve coordinates for ${location.name}`, status)
              resolve(null);
            }
        });
    });
}
  
  private async loadGoogleMapsScript() {
    return new Promise<void>((resolve) => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`;
        script.async = true;
        document.head.appendChild(script);
        script.onload = async () => {
          this.apiLoaded = true;
          if (script?.parentNode) {
            script.parentNode.removeChild(script);
          }
          resolve();
        };
      } else {
        this.apiLoaded = true;
        resolve();
      }
    })
  }
}
